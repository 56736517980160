import styles from './ScoreTable.module.scss';

function ScoreCell({ value, status }) {
    return (
        <td className={`${status === 'correct' && styles.correct} ${status === 'incorrect' && styles.incorrect}`}>
            { value }
        </td>
    )
}

export default ScoreCell;