import React from 'react';
import styles from './PrimaryButton.module.scss';
import SyncLoader from 'react-spinners/SyncLoader';

const PrimaryButton = React.forwardRef(({ children, onClick, disabled = false, loading = false }, ref) => {
    return (
        <button ref={ref} className={`${styles.button} ${loading ? styles.loading : ''}`} onClick={onClick} disabled={disabled}>
            <span className={styles.text}>{children}</span>
            <SyncLoader className={styles.loader} loading={loading} size={7} color={'#fff'} />
        </button>
    )
})

export default PrimaryButton;