import styles from './Select.module.scss';

function Select({ children, value, onChange }) {
    return (
        <select className={styles.select} value={value} onChange={onChange}>
            {children}
        </select>
    );
}

export default Select;