import styles from './ScoreTable.module.scss';
import ScoreRow from './ScoreRow';
import Popup from 'reactjs-popup';

function ScoreTable({ questions, studentData }) {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th className={styles.nameCol}>Namn</th>
                    <th className={styles.scoreCol}>Rätt</th>
                    { questions.map((question, index) => {
                        return (
                            <Popup
                                trigger={<th key={index} className={styles.questionCol}>Fråga {index + 1}</th>}
                                position={'top center'}
                                on={['hover', 'focus']}
                                closeOnDocumentClick
                                key={index}
                            >
                                <span>{question.title}</span>
                            </Popup>
                        )
                    }) }
                </tr>
            </thead>
            <tbody>
                { studentData.length > 0 ? studentData.map((student, index) => (
                    <ScoreRow key={index} student={student} />
                )) : <tr><td colSpan={questions.length + 2}>Inga elever</td></tr> }
                <tr className={styles.totalRow}>
                    <td>Klassresultat</td>
                    <td></td>
                    { questions.map((_, index) => <td key={index}></td>) }
                </tr>
            </tbody>
        </table>
    );
}

export default ScoreTable;