import ContainerLg from '../containers/ContainerLg';
import Section from '../containers/Section';
import Navbar from '../components/navbars/Navbar';
import HomeHeader from '../components/headers/HomeHeader';
import HomeShortcuts from '../components/pages/home/shortcuts/HomeShortcuts';
import NormalParagraph from '../components/text/paragraphs/NormalParagraph';
import HeadingXl from '../components/text/headings/HeadingXl';
import HeadingXxl from '../components/text/headings/HeadingXxl';
import FeatureSection from '../components/pages/home/feature_sections/FeatureSection';
import PrimaryButton from '../components/buttons/PrimaryButton';
import CenterContent from '../containers/CenterContent';
import MainFooter from '../components/footers/MainFooter';

function Home() {
    return (
        <div>
            <Navbar />
            <HomeHeader />
            <ContainerLg>
                <HomeShortcuts />
                <Section>
                    <HeadingXl>Vad är Studian?</HeadingXl>
                    <NormalParagraph>
                        Studian är den innovativa webbplatsen utvecklad av lärare och elever, designad för att förbättra undervisningen för både elever och lärare. På Studian anser vi att lärande ska vara roligt, engagerande och anpassningsbart till varje elevs unika behov. Det är därför vi har skapat en plattform som gör det möjligt för lärare att enkelt skapa anpassade läxor, med en rad effektiva inlärningsmetoder, som inte bara är interaktiva och spännande utan också resultatrika för att hjälpa eleverna att intressera sig och hålla hög nivå. Utöver detta möjliggör Studian även lärare att skapa läxförhör för att kontrollera elevernas inlärning. Med Studian kan lärare ta sina lektioner till nästa nivå och eleverna kan uppleva ett roligt, personligt förhållningssätt till lärande. Registrera dig idag och börja utforska Studians värld!
                    </NormalParagraph>
                </Section>
                <FeatureSection title="Skapa motiverande läxor" imgSrc="/assets/images/graphics/feature_sections/Sets.svg">
                    <p>Motivation och engagemang är drivkraften för elever och deras prestation, därför kan du på Studian skapa interaktiva läxor online. Eleverna kan själva välja var, när och framför allt hur de vill plugga. Vi erbjuder en rad roliga och effektiva inlärningsmetoder som flashcards, skrivtest och spel. Läxorna är enkla att skapa, redigera och dela med elever och kollegor.</p>
                    <PrimaryButton onClick={() => { window.location.href = '/create/set' }}>Skapa en läxa</PrimaryButton>
                </FeatureSection>
                <FeatureSection title="Starta interaktiva läxförhör" imgSrc="/assets/images/graphics/feature_sections/Tests.svg" inverted="true">
                    <p>Skapa och håll i läxförhör live i klassrummet. Läxförhöret kan vara baserade på en tidigare läxa, eller skapade helt från grunden. Du kan välja om svaren på frågorna ska vara exakta, som i glosförhör, eller öppna där du som lärare bedömer. Läxförhören är minimalistiska och hjälper elever att hålla fokus och koncentrera sig på frågorna.</p>
                    <PrimaryButton onClick={() => { window.location.href = '/new/test' }}>Skapa ett läxförhör</PrimaryButton>
                </FeatureSection>
                <FeatureSection title="Håll kontakten" imgSrc="/assets/images/graphics/feature_sections/Contact.svg">
                    <p>Studian är också ett forum och en samlingsplats. Här kan du hålla kontakten med kollegor och elever. Lärare kan dela läxor och läxförhör med andra lärare för ett effektivare arbete, och konversera med elever om skolarbetet. Elever kan också skicka meddelanden till varandra och prata om både skolarbete och fritid. Studian är en gemenskap och en samlingsplats för alla.</p>
                    <PrimaryButton onClick={() => { window.location.href = '/chat' }}>Skicka ett meddelande</PrimaryButton>
                </FeatureSection>
                <Section>
                    <HeadingXl>Varför Studian?</HeadingXl>
                    <NormalParagraph>
                        Studian är det perfekta valet för alla som värdesätter ett innovativt och elevcentrerat lärande. Till skillnad från andra inlärningsplattformar har Studian utvecklats av ett team av erfarna elever och lärare, som förstår de utmaningar som både elever och pedagoger står inför i undervisningen. Vår plattform är utformad för att möta dessa utmaningar, med engagerande, anpassningsbara läxor som är både roliga och effektiva för elever i alla åldrar. Men det är inte allt - Studian erbjuder också kraftfulla verktyg för lärare, vilket gör det möjligt för dig att hålla koll på dina elevers framsteg och skräddarsy din undervisning efter deras individuella behov. Så oavsett om du är en lärare som vill utveckla dina lektioner eller en elev som söker ett mer engagerande och effektivt sätt att lära sig på, så är Studian det perfekta valet - utvecklat av elever och lärare, för elever och lärare.
                    </NormalParagraph>
                </Section>
                <Section>
                    <span style={{ textAlign: 'center' }}>
                        <HeadingXxl>Känner du dig redo?</HeadingXxl>
                    </span>
                    <CenterContent>
                        <PrimaryButton>Tveka inte, registrera dig!</PrimaryButton>
                    </CenterContent>
                </Section>
            </ContainerLg>
            <MainFooter />
        </div>
    );
}

export default Home;