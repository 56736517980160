import { ShareIos, Link } from "iconoir-react";
import Menu from "../../../popups/menus/Menu";

function ShareSetMenu({ setId, className }) {
    function copyLink(closeMenu) {
        const link = `https://studian.se/set/${setId}`;
        navigator.clipboard.writeText(link);
        closeMenu();
    }

    const menuItems = [
        {
            label: 'Kopiera länk',
            icon: <Link />,
            onClick: copyLink
        }
    ];

    return (
        <Menu
            triggerElement={
                <span className={className}>
                    <ShareIos />
                </span>
            }
            items={menuItems}
        />
    );
}

export default ShareSetMenu;