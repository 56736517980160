import styles from './SearchInput.module.scss';
import { Search } from 'iconoir-react';

function SearchInput({ placeholder, onChange }) {
    return (
        <div className={styles.inputWrapper}>
            <Search />
            <input type="text" placeholder={placeholder} className={styles.input} onChange={onChange} />
        </div>
    );
}

export default SearchInput;