import styles from './WelcomeBox.module.scss';
import IconInput from '../../inputs/IconInput';
import { Lock } from 'iconoir-react';
import BoxButton from '../../buttons/BoxButton';
import { useState } from 'react';
import ErrorMessage from '../../popups/message/ErrorMessage';

function ForgotPasswordBox({ onSubmit, errorMessage, buttonDisabled, loading }) {
    const [ password, setPassword ] = useState('');

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit() {
        if (!password) {
            return;
        }
        
        onSubmit(password);
    }

    return (
        <div className={styles.box}>
            <h1 className={styles.title}>Nytt lösenord</h1>
            <div className={styles.inputWrapper}>
                <p>Ange ett nytt lösenord. För att inte glömma det kan du skriva ner det på ett säkert ställe, eller använda en lösenordshanterare.</p>
                <IconInput 
                    label='Nytt lösenord' 
                    placeholder='Skriv lösenord' 
                    type="password" 
                    name="password" 
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                    <Lock />
                </IconInput>
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <BoxButton onClick={handleSubmit} disabled={buttonDisabled} loading={loading}>Uppdatera lösenord</BoxButton>
        </div>
    );
}

export default ForgotPasswordBox;
