import styles from './Sidebar.module.scss';
import { BookmarkBook, ClipboardCheck, QuoteMessage, Cloud, Community, Leaderboard, StatsUpSquare } from 'iconoir-react';

function Sidebar({ selected }) {
    return (
        <div className={styles.sidebar}>
            <a href="/dashboard" className={`${styles.item} ${selected === 'sets' ? styles.selected : ''}`}>
                <BookmarkBook className={styles.icon} />
                <span className={styles.title}>Läxor</span>
            </a>
            <a href="/dashboard/tests" className={`${styles.item} ${selected === 'tests' ? styles.selected : ''}`}>
                <ClipboardCheck className={styles.icon} />
                <span className={styles.title}>Läxförhör</span>
            </a>
            <a href="/dashboard/mindmirrors" className={`${styles.item} ${selected === 'mindmirrors' ? styles.selected : ''}`}>
                <QuoteMessage className={styles.icon} />
                <span className={styles.title}>Tankespeglar</span>
            </a>
            <a href="/dashboard/mindclouds" className={`${styles.item} ${selected === 'mindclouds' ? styles.selected : ''}`}>
                <Cloud className={styles.icon} />
                <span className={styles.title}>Tankemoln</span>
            </a>
            <a href="/dashboard/quizes" className={`${styles.item} ${selected === 'quizes' ? styles.selected : ''}`}>
                <Leaderboard className={styles.icon} />
                <span className={styles.title}>Quiz</span>
            </a>
            <a href="/dashboard/polls" className={`${styles.item} ${selected === 'polls' ? styles.selected : ''}`}>
                <StatsUpSquare className={styles.icon} />
                <span className={styles.title}>Omröstning</span>
            </a>
            <a href="/dashboard/classes" className={`${styles.item} ${selected === 'classes' ? styles.selected : ''}`}>
                <Community className={styles.icon} />
                <span className={styles.title}>Klasser</span>
            </a>
        </div>
    );
}

export default Sidebar;