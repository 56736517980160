import styles from './ScoreTable.module.scss';
import ScoreCell from './ScoreCell';
import Popup from 'reactjs-popup';
import { useState, useEffect } from 'react';
import { SnowFlake } from 'iconoir-react';
import PrimaryButton from '../../buttons/PrimaryButton';
import axios from 'axios';

function ScoreRow({ student }) {
    const [ answers, setAnswers ] = useState([]);
    const [ loadingUnfreeze, setLoadingUnfreeze ] = useState(false);

    // Get answers
    useEffect(() => {
        setAnswers(Array.isArray(student.answers) ? student.answers : []);
    }, [student]);

    // Unfreeze student
    const unfreezeStudent = () => {
        setLoadingUnfreeze(true);

        axios.put('https://api.theodorlundin.se/studian/test/live/unfreeze-student', {
            student_id: student.id
        }, {
            withCredentials: true
        }).finally(() => {
            setLoadingUnfreeze(false);
        });
    }

    return (
        <tr className={styles.studentRow}>
            <td className={styles.studentName}>
                {student.name}
                { !student.done ?
                    <Popup
                        trigger={
                            <span className={styles.status}></span>
                        }
                        position={'bottom center'}
                        on={['hover', 'focus']}
                        closeOnDocumentClick
                    >
                        <span>Eleven är inte färdig</span>
                    </Popup>
                    : <Popup
                        trigger={
                            <span className={`${styles.status} ${styles.done}`}></span>
                        }
                        position={'bottom center'}
                        on={['hover', 'focus']}
                        closeOnDocumentClick
                    >
                        <span>Eleven är färdig</span>
                    </Popup>
                }
                { student.frozen ?
                    <Popup
                        trigger={
                            <SnowFlake className={styles.frozenIcon} />
                        }
                        position={'bottom center'}
                        on={['hover', 'focus']}
                        closeOnDocumentClick
                    >
                        <div className={styles.frozenPopup}>
                            <span>Eleven är fryst</span>
                            <PrimaryButton onClick={unfreezeStudent} loading={loadingUnfreeze}>Släpp elev</PrimaryButton>
                        </div>
                    </Popup> : ''
                }
            </td>
            <td>{student.score}%</td>
            {answers.map((answer, index) => (
                <ScoreCell key={index} value={answer.answer} status={answer.status} />
            ))}
        </tr>
    );
}

export default ScoreRow;