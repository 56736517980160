import TestCard from "../../../tests/cards/TestCard";
import { useState, useEffect } from 'react';
import axios from 'axios';
import LazyLoad from "react-lazy-load";
import styles from "../Dashboard.module.scss";

function TestScreen() {
    const [ testIds, setTestIds ] = useState([]);
    const [ renderedTestIds, setRenderedTestIds ] = useState([]);
    
    function retrieveTests() {
        axios.get(`https://api.theodorlundin.se/studian/test/get/by-creator?active=true`, {
            withCredentials: true
        }).then(response => {
            setTestIds(response.data.tests);
            setRenderedTestIds(response.data.tests);
        }).catch(error => {
            console.error('Error with API call:', error.message);
        });
    }

    useEffect(() => {
        retrieveTests();
    }, []);

    return (
        <>  
            <h2 style={{ marginBlock: '1rem' }}>Alla läxförhör</h2>
            { testIds.length > 0 ? 
                (
                    <>
                        <div className={styles.testCardWrapper}>
                            { renderedTestIds.map((testId) => <TestCard key={testId} testId={testId} />) }
                        </div>
                    </>
                ) : (
                    <div className={styles.noContent}>
                        <LazyLoad className={styles.lazyLoad}>
                            <img src="/assets/images/graphics/illustrations/shrug.png" alt="" />
                        </LazyLoad>
                        <h1>Skapa ditt första läxförhör!</h1>
                    </div>
                )
            }
        </>
    );
}

export default TestScreen;