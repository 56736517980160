import Navbar from '../../components/navbars/Navbar';
import GradientContainer from '../../containers/GradientContainer';
import ForgotPasswordBox from '../../components/boxes/welcome/ForgotPasswordBox';
import axios from 'axios';
import { useState } from 'react';

function ForgotPassword() {
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    async function handleSubmit(email) {
        setButtonDisabled(true);
        setLoading(true);

        setTimeout(() => {
            axios.post('https://api.theodorlundin.se/studian/auth/start-password-change', {
                email: email
            }, {
                withCredentials: true
            }).then(() => {
                setError(null);
                setSuccess('Du har fått en länk skickad till din mail');
                setLoading(false);
            }).catch(error => {
                const message = error.response?.data?.error || 'Något gick fel. Försök igen eller kontakta oss.';
                setError(message);
                setLoading(false);
                setButtonDisabled(false);
            });
        }, 1000);
    }

    return (
        <div>
            <Navbar />
            <GradientContainer centerContent="true">
                <ForgotPasswordBox onSubmit={handleSubmit} errorMessage={error} successMessage={success} buttonDisabled={buttonDisabled} loading={loading} />
            </GradientContainer>
        </div>
    );
}

export default ForgotPassword;