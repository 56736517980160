import styles from './ClickableDropdown.module.scss';
import { NavArrowDown } from 'iconoir-react';

function ClickableDropdown({ children, title, fill }) {
    const className = `${styles.dropdown} ${fill === 'true' ? styles.fill : ''}`;
    
    return (
        <label className={className}>
            <input type="checkbox" className={styles.input}></input>
            <span className={styles.button}>
                <span className={styles.title}>{title}</span>
                <NavArrowDown className={styles.arrow} />
            </span>
            <div className={styles.body}>
                {children}
            </div>
        </label>
    );
}

export default ClickableDropdown;