import styles from './TestCard.module.scss';
import { EditPencil, Play, Trash, ClipboardCheck, Archive, Pause, OpenInWindow } from 'iconoir-react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Popup from 'reactjs-popup';

function TestCard({ testId }) {
    const [ testData, setTestData ] = useState(null);
    
    // Get test data
    function getTestData() {
        axios.get(`https://api.theodorlundin.se/studian/test/get/data?test_id=${testId}`)
            .then(response => {
                setTestData(response.data.test);
            })
            .catch(error => {
                console.error('Error with API call: ', error.message);
            });
    }

    useEffect(getTestData, [ testId ]);

    // Handle API calls
    function handleStartTest() {
        axios.put('https://api.theodorlundin.se/studian/test/live/start-test', {
            test_id: testId
        }, {
            withCredentials: true
        }).then(response => {
            getTestData();
        }).catch(error => {
            console.error('Error with API call: ', error);
        });
    }

    function handleCloseTest() {
        axios.put('https://api.theodorlundin.se/studian/test/live/close-test', {
            test_id: testId
        }, {
            withCredentials: true
        }).then(response => {
            getTestData();
        }).catch(error => {
            console.error('Error with API call: ', error);
        });
    }

    return (
        <div 
            className={styles.card} 
            tabIndex={0}
        >
            <div className={styles.body}>
                <div className={styles.header}>
                    <ClipboardCheck className={styles.icon} />
                    <div className={styles.titles}>
                    { testData ? 
                        <>
                            <span className={styles.title}>{ testData.name }</span>
                            <span className={styles.date}>Skapad { testData.created_readable }</span>
                        </>
                        : <Skeleton width={200} />
                    }
                    </div>
                </div>
                <div className={styles.actions}>
                    <div className={styles.liveActions}>
                        { testData?.live === '1' || false ? (
                            <>
                                <span className={styles.live}>LIVE</span>
                                {/* <span className={styles.students}>25 ELEVER</span> */}
                                <Popup
                                    trigger={<Pause onClick={handleCloseTest} />}
                                    position={'bottom center'}
                                    on={['hover', 'focus']}
                                    closeOnDocumentClick
                                >
                                    <span style={{textAlign: 'center'}}>Spara och avsluta</span>
                                </Popup>
                                <Popup
                                    trigger={
                                        <a href={`/test/host/${testData.live_id}`}>
                                            <OpenInWindow />
                                        </a>
                                    }
                                    position={'bottom center'}
                                    on={['hover', 'focus']}
                                    closeOnDocumentClick
                                >
                                    <span style={{textAlign: 'center'}}>Öppna läxförhör</span>
                                </Popup>
                            </>
                        ) : (
                            <>
                                <Popup
                                    trigger={<Play onClick={handleStartTest} />}
                                    position={'bottom center'}
                                    on={['hover', 'focus']}
                                    closeOnDocumentClick
                                >
                                    <span style={{textAlign: 'center'}}>Starta</span>
                                </Popup>
                                <Popup
                                    trigger={<Archive />}
                                    position={'bottom center'}
                                    on={['hover', 'focus']}
                                    closeOnDocumentClick
                                >
                                    <span style={{textAlign: 'center'}}>Avslutade förhör</span>
                                </Popup>
                            </>
                        ) }
                    </div>
                    <div className={styles.testActions}>
                        <Popup
                            trigger={<EditPencil />}
                            position={'bottom center'}
                            on={['hover', 'focus']}
                            closeOnDocumentClick
                        >
                            <span style={{textAlign: 'center'}}>Redigera</span>
                        </Popup>
                        <Popup
                            trigger={<Trash />}
                            position={'bottom center'}
                            on={['hover', 'focus']}
                            closeOnDocumentClick
                        >
                            <span style={{textAlign: 'center'}}>Ta bort</span>
                        </Popup>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TestCard;