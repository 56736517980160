import styles from './UserLink.module.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import PfpMedium from './PfpMedium';

function UserLink({ userId }) {
    const [ username, setUsername ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        axios.get(`https://api.theodorlundin.se/studian/user/get-user-data?user_id=${userId}`)
        .then(response => {
            setUsername(response.data?.user_data?.username || 'Okänd');
            setLoading(false);
        }).catch(() => {
            setLoading(true);
        })
    })

    return (
        <>
            {loading ? (
                <Skeleton width={150} height={48} />
            ) : (
                <a href={`/user/${username}`} className={styles.userLink}>
                    <PfpMedium username={username} />
                    <span className={styles.username}>{username}</span>
                </a>
            )}
        </>
    );
}

export default UserLink;