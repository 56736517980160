import styles from './HeadingXl.module.scss';

function HeadingXl({ children }) {
    return (
        <h1 className={styles.heading}>
            {children}
        </h1>
    );
}

export default HeadingXl;