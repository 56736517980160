import styles from './ContainerSm.module.scss';

function ContainerSm({ children, centerContent }) {
    return (
        <div className={`${styles.container} ${centerContent === 'true' ? styles.centerContent : ''}`}>
            {children}
        </div>
    );
}

export default ContainerSm;