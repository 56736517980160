import React from 'react';
import styles from './IconInput.module.scss';

function IconInput({ placeholder, label, type, name, children, onChange, onKeyDown, onKeyUp }) {
    const labelElement = (
        <label className={styles.label} htmlFor={name}>{label}</label>
    );

    // Focus input on click
    function handleClick(e) {
        if (e.target.tagName !== 'INPUT') {
            const child = e.target.children[1];
            if (child && typeof child.focus === 'function' && !child.disabled) {
                child.focus();
            }
        }
    }

    return (
        <div className={styles.inputWrapper} onClick={handleClick}>
            {label && labelElement}
            <div className={styles.iconInput}>
                <span className={styles.iconWrapper}>{children}</span>
                <input 
                    type={type} 
                    placeholder={placeholder} 
                    name={name} 
                    className={styles.input} 
                    onChange={onChange} 
                    onKeyDown={onKeyDown} 
                    onKeyUp={onKeyUp}
                />
            </div>
        </div>
    );
}

export default IconInput;