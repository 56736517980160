import styles from './HeadingXxl.module.scss';

function HeadingXxl({ children }) {
    return (
        <h1 className={styles.heading}>
            {children}
        </h1>
    );
}

export default HeadingXxl;