import ContainerSm from '../containers/ContainerSm';
import Section from '../containers/Section';
import Navbar from '../components/navbars/Navbar';
import MainFooter from '../components/footers/MainFooter';
import HeadingXxl from '../components/text/headings/HeadingXxl';
import HeadingLg from '../components/text/headings/HeadingLg';
import NormalParagraph from '../components/text/paragraphs/NormalParagraph';

function Conditions() {
    return (
        <div>
            <Navbar />
            <ContainerSm>
            <Section>
                    <HeadingXxl>Användarvillkor</HeadingXxl>
                    <HeadingLg>Introduktion</HeadingLg>
                    <NormalParagraph>
                        Välkommen till Studian! Vi vill att alla ska hålla en god ton och trivas här, därför måste du acceptera dessa villkor och följa riktlinjerna när du använder sidan.
                    </NormalParagraph>
                    <HeadingLg>Accepterande av villkor</HeadingLg>
                    <NormalParagraph>
                        Genom att använda denna hemsida bekräftar du att du läst igenom, förstått och accepterat dessa vilkor. Accepterar du inte villkoren avråder vi från att använda denna hemsida.
                    </NormalParagraph>
                    <HeadingLg>Modifierande av villkor</HeadingLg>
                    <NormalParagraph>
                        Vi har rättigheterna att modifiera och uppdatera villkoren oberoende av omständigheterna. Din fortsatta användning av hemsidan efter sådana uppdateringar innebär att du accepterar de nya villkoren. Alla användare meddelas om uppdateringar av villkor och riktlinjer.
                    </NormalParagraph>
                    <HeadingLg>Användning av hemsidan</HeadingLg>
                    <NormalParagraph>
                        Denna hemsida är avsedd för användning i lärande syfte, för att skapa lärandematerial online. Du samtycker till att endast använda hemsidan i lagliga syften och i enlighet med dessa villkor.
                    </NormalParagraph>
                    <HeadingLg>Användarmaterial</HeadingLg>
                    <NormalParagraph>
                        Denna hemsida tillåter dig att ladda upp, spara och på andra sätt skapa öppet material innehållande text och bilder. Du erhåller rättigheterna till ditt användarmaterial, men du beviljar oss en icke-exklusiv licens att använda, kopiera, modifiera och visa ditt material i syfte att driva och tillhandahålla hemsidan till dig och våra användare. Allt material måste följa våra riktlinjer.
                    </NormalParagraph>
                    <HeadingLg>Immateriella rättigheter</HeadingLg>
                    <NormalParagraph>
                        Denna hemsida och dess innehåll, inkluderat användarmaterial i form av text och bilder, och mjukvara är skyddat av upphovsrättslagen. Vi äger rättigheterna till hela hemsidans innehåll och titlar. Du får inte använda dig utav skyddat innehåll från denna hemsida utan vårt tydliga medgivande.
                    </NormalParagraph>
                    <HeadingLg>Förmaningar</HeadingLg>
                    <NormalParagraph>
                        Vi kan inte garantera att hemsidan kommer fungera felfritt eller oavbrutet, och vi står inte för eventuella förluster eller avbrott som kan uppstå. Däremot jobbar vi för att undvika de eventuella felen på hemsidan.
                    </NormalParagraph>
                    <HeadingLg>Ansvarsbegränsningar</HeadingLg>
                    <NormalParagraph>
                        Vi kan under inga omständigheter hållas ansvariga för några förluster, skador, följskador och straffskador som uppstår till följd av användningen av hemsidan och dess innehåll, även om vi varit medvetna om den eventuella orsaken.
                    </NormalParagraph>
                    <HeadingLg>Skadeersättningar</HeadingLg>
                    <NormalParagraph>
                        Du accepterar att ersätta och hålla oss oskadliga och ifrån alla typer av anspråk, skador och kostnader relaterat till din användning av hemsidan.
                    </NormalParagraph>
                    <HeadingLg>Uppsägning</HeadingLg>
                    <NormalParagraph>
                        Vi har rätt att säga upp eller suspendera din åtkomst till vår hemsida, med eller utan orsak, med eller utan tillkännagivande, med omedelbar verkan.
                    </NormalParagraph>
                    <HeadingLg>Konton</HeadingLg>
                    <NormalParagraph>
                        Du behöver skapa ett konto för att komma åt hemsidans alla funktioner. Du är ansvarig för att upprätthålla konfidentialiteten för din kontoinformation, inkluderat ditt lösenord, och för att begränsa åtkomsten till ditt konto. Du står för det som sker under ditt konto. Läs mer i integritetspolicyn om hur vi hanterar och skyddar dina personuppgifter.
                    </NormalParagraph>
                    <NormalParagraph>
                        <i>Denna text uppdaterades senast den 5:e augusti 2024 och ersätter alla tidigare versioner.</i>
                    </NormalParagraph>
                </Section>
                <Section>
                    <HeadingXxl>Riktlinjer</HeadingXxl>
                    <HeadingLg>Introduktion</HeadingLg>
                    <NormalParagraph>
                        För att alla ska hålla en god ton och trivas måste du följa dessa riktlinjer när du använder hemsidan. Bryter du mot riktlinjerna kan ditt konto bli avstängd från fortsatt använding. Allt innehåll som kan tänkas bryta mot dessa riktlinjer ska rapporteras.
                    </NormalParagraph>
                    <HeadingLg>Respekt och inkludering</HeadingLg>
                    <NormalParagraph>
                        Skapa material som är respektfullt och inkluderande gentemot alla användare. Undvik att skapa innehåll som är kränkande, nedvärderande, eller diskriminerande baserat på kön, etnicitet, religion, sexuell läggning eller någon annan faktor. Vi uppmuntrar till en positiv och mångfaldig inlärningsmiljö.
                    </NormalParagraph>
                    <HeadingLg>Olämpligt innehåll</HeadingLg>
                    <NormalParagraph>
                        Undvik att skapa material som innehåller explicita eller sexuellt inriktade bilder, texter eller symboler. Detta inkluderar även material som kan vara stötande, vulgärt eller på annat sätt olämpligt för vår plattform. Vi strävar efter att behålla en professionell och lämplig atmosfär för alla användare.
                    </NormalParagraph>
                    <HeadingLg>Autenticitet och originalitet</HeadingLg>
                    <NormalParagraph>
                        Se till att skapa material som är autentiskt och originalt. Respektera upphovsrätten genom att inte kopiera eller använda andras verk utan tillstånd. Om du använder befintligt material från andra källor, se till att tillhandahålla korrekt referens och erkänna originalskaparna.
                    </NormalParagraph>
                    <HeadingLg>Korrekthet och tillförlitlighet</HeadingLg>
                    <NormalParagraph>
                        När du skapar material, se till att det är korrekt och tillförlitligt. Dubbelkolla din information och undvik spridning av falska eller vilseledande fakta. Vi vill att  Studian ska vara en plats där användarna kan lita på och dra nytta av kvalitativt och pålitligt lärande.
                    </NormalParagraph>
                    <HeadingLg>Lämpligt språk och ton</HeadingLg>
                    <NormalParagraph>
                        Använd ett lämpligt och professionellt språk när du skapar material på  Studian. Undvik användning av obscena, provokativa eller vulgära uttryck. Vi vill att vår plattform ska vara en positiv och konstruktiv plats för lärande och interaktion.
                    </NormalParagraph>
                    <NormalParagraph>
                        <i>Denna text uppdaterades senast den 5:e augusti 2024 och ersätter alla tidigare versioner.</i>
                    </NormalParagraph>
                </Section>
            </ContainerSm>
            <MainFooter />
        </div>
    );
}

export default Conditions;