import { useEffect, useRef, useState } from 'react';
import styles from './Join.module.scss';
import ErrorMessage from '../../popups/message/ErrorMessage';

function JoinCode({ onSubmitCode, error, loading }) {
    const [ code, setCode ] = useState([]);
    const inputRefs = useRef([]);

    const handleInput = (e, index) => {
        const value = e.target.value;

        setCode(current => {
            const newCode = [...current];
            newCode[index] = value;
            return newCode;
        });

        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleInputKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            if (index > 0 && !inputRefs.current[index].value) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    useEffect(() => {
        if (error !== null) {
            setCode([]);
            inputRefs.current[0].focus();
        }
    }, [ error ]);

    useEffect(() => {
        if (code.length === 6) {
            inputRefs.current.forEach(ref => {
                onSubmitCode(code.join(''));
            });
        }
    }, [ code, onSubmitCode ]);

    return (
        <div className={styles.form}>
            <h1>Gå med i läxförhör</h1>
            <div className={`${styles.inputWrapper} ${loading ? styles.loading : ''}`}>
                {Array.from({ length: 6 }).map((_, index) => (
                    <input
                        key={index}
                        type="text"
                        className={styles.input}
                        inputMode="numeric"
                        value={code[index] || ''}
                        maxLength="1"
                        placeholder='-'
                        ref={(el) => (inputRefs.current[index] = el)}
                        onInput={(e) => handleInput(e, index)}
                        onKeyDown={(e) => handleInputKeyDown(e, index)}
                        { ...(index === 0 && { autoFocus: true }) }
                        disabled={loading}
                    />
                ))}
            </div>
            { error && <ErrorMessage message={error} /> }
            <img className={styles.illustration} src="/assets/images/graphics/illustrations/tests.webp" alt="Gå med i läxförhör" />
        </div>
    );
}

export default JoinCode;