import styles from './SuccessMessage.module.scss';
import { CheckCircle } from 'iconoir-react';

function SuccessMessage({ message }) {
    return (
        <div className={styles.successMessage}>
            <CheckCircle className={styles.icon} />
            <span className={styles.message}>{message}</span>
        </div>
    );
}

export default SuccessMessage;