import styles from './CreateSet.module.scss';
import Navbar from "../../../components/navbars/Navbar";
import Auth from "../../../components/auth/Auth";
import ContainerSm from '../../../containers/ContainerSm';
import Section from '../../../containers/Section';
import CreateSetForm from "../../../components/pages/create/sets/CreateSetForm";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ErrorMessage from "../../../components/popups/message/ErrorMessage";
import { useState } from 'react';
import axios from 'axios';

function CreateSet() {
    const [ setName, setSetName ] = useState('');
    const [ setSubject, setSetSubject ] = useState('');
    const [ terms, setTerms ] = useState([]);
    const [ error, setError ] = useState('');
    const [ loading, setLoading ] = useState(false);

    function handleUpdate(nameValue, subjectValue, termValues) {
        setSetName(nameValue);
        setSetSubject(subjectValue);
        setTerms(termValues);
    }

    function handleSubmit() {
        if (loading) return;
        setLoading(true);

        // Prepare the FormData object
        const formData = new FormData();
        formData.append('name', setName);
        formData.append('subject', setSubject);

        terms.forEach((term, index) => {
            formData.append(`terms[${index}][term]`, term.term);
            formData.append(`terms[${index}][definition]`, term.definition);
            formData.append(`terms[${index}][term_image]`, term.termImage);
            formData.append(`terms[${index}][definition_image]`, term.definitionImage);
        });

        // Make the POST request
        axios.post('https://api.theodorlundin.se/studian/set/manage/create', formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(() => {
            window.location.href = '/dashboard';
        }).catch(error => {
            setLoading(false);
            const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
            setError(message);
        });
    }

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <ContainerSm>
                    <Section>
                        <div className={styles.header}>
                            <h2>Skapa läxa</h2>
                            <PrimaryButton onClick={handleSubmit} loading={loading} disabled={loading}>Skapa</PrimaryButton>
                        </div>
                        { error ? <ErrorMessage message={error} /> : null }
                        <CreateSetForm onChange={handleUpdate} loading={loading} />
                    </Section>
                </ContainerSm>
            </Auth>
        </div>
    );
}

export default CreateSet;