import styles from './Student.module.scss';
import Navbar from '../../components/navbars/Navbar';
import ContainerSm from '../../containers/ContainerSm';
import { ArrowLeftCircle, ArrowRightCircle, Check, CheckCircle } from 'iconoir-react';
import Popup from 'reactjs-popup';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ErrorMessage from '../../components/popups/message/ErrorMessage';

function Student() {
    const [ questions, setQuestions ] = useState([]);
    const [ globalIndex, setGlobalIndex ] = useState(0);
    const [ switching, setSwitching ] = useState(0); // -1 for previous, 1 for next
    const [ answers, setAnswers ] = useState(null);
    const [ currentAnswer, setCurrentAnswer ] = useState(null);
    const [ freezeMode, setFreezeMode ] = useState(false);
    const [ frozen, setFrozen ] = useState(false);
    const [ error, setError ] = useState(null);

    // Get questions
    useEffect(() => {
        axios.get('https://api.theodorlundin.se/studian/test/student/get-questions', {
            withCredentials: true
        }).then(response => {
            setQuestions(response.data.questions);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    }, [ setQuestions ]);

    // Get progress
    useEffect(() => {
        axios.get('https://api.theodorlundin.se/studian/test/student/get-progress', {
            withCredentials: true
        }).then(response => {
            setAnswers(response.data?.progress || []);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    }, []);

    useEffect(() => {
        if (!Array.isArray(answers)) return;
        if (!Array.isArray(answers[globalIndex])) return;
        
        setCurrentAnswer(answers[globalIndex]);
    }, [ answers, globalIndex ]);

    // Check freeze mode
    useEffect(() => {
        axios.get('https://api.theodorlundin.se/studian/test/student/is-freeze-mode', {
            withCredentials: true
        }).then(res => {
            setFreezeMode(res.data.freeze_mode);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    });

    // Freeze student
    function checkFrozen() {
        axios.get('https://api.theodorlundin.se/studian/test/student/is-frozen', {
            withCredentials: true
        }).then(res => {
            setFrozen(res.data.frozen);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    }

    setInterval(() => { // Every second
        checkFrozen();
    }, 1000);

    useEffect(() => { // On load
        checkFrozen();
    });

    window.onblur = () => { // On window unfocus
        if (!freezeMode) return;
        setFrozen(true);
        axios.put('https://api.theodorlundin.se/studian/test/student/freeze', {}, {
            withCredentials: true
        });
    };

    // Handle type and answer component
    const answerComponent_exactOpen = () => {
        return (
            <div className={styles.answerExactOpen}>
                <textarea 
                    placeholder='Skriv ditt svar' 
                    autoFocus 
                    onChange={(e) => setAnswers(current => {
                        if (frozen) return current;
                        const newAnswers = [...current];
                        newAnswers[globalIndex] = [e.target.value];
                        return newAnswers;
                    })}
                    disabled={frozen}
                    value={(currentAnswer && currentAnswer[0]) || ''}
                ></textarea>
            </div>
        );
    }

    const answerComponent_multiplechoice = () => {
        return (
            <div className={styles.answerMultiplechoice}>
                { questions[globalIndex]?.alternatives.map((alternative, index) => {
                    return (
                        <span 
                            className={`${styles.alternative} ${(currentAnswer && currentAnswer[0]) === index ? styles.selected : ''}`} 
                            key={index} 
                            onClick={() => setAnswers(current => {
                                if (frozen) return current;
                                const newAnswers = [...current];
                                newAnswers[globalIndex] = [index];
                                return newAnswers;
                            })}
                        >
                            <span className={styles.radio}></span>
                            <span className={styles.label}>{ alternative }</span>
                        </span>
                    );
                }) }
            </div>
        );
    }

    const answerComponent_checkboxes = () => {
        return (
            <div className={styles.answerCheckboxes}>
                { questions[globalIndex]?.alternatives.map((alternative, index) => {
                    return (
                        <span 
                            className={`${styles.alternative} ${answers[globalIndex]?.includes(index) ? styles.selected : ''}`} 
                            key={index} 
                            onClick={() => handleCheckboxClick(index)}
                        >
                            <span className={styles.checkbox}><Check /></span>
                            <span className={styles.label}>{ alternative }</span>
                        </span>
                    );
                }) }
            </div>
        );
    }

    const answerComponent = () => {
        switch (questions[globalIndex]?.type) {
            case 'exact':
                return answerComponent_exactOpen();
            case 'open':
                return answerComponent_exactOpen();
            case 'multiple-choice':
                return answerComponent_multiplechoice();
            case 'checkboxes':
                return answerComponent_checkboxes();
            default:
                return <Skeleton width={'100%'} height={100} />
        }
    }

    // Handle checkboxes
    const handleCheckboxClick = (index) => {
        if (frozen) return;

        setAnswers(current => {
            const newAnswers = [...current];
            const selected = newAnswers[globalIndex] || [];
    
            newAnswers[globalIndex] = selected.includes(index)
                ? selected.filter(i => i !== index)
                : [...selected, index];
    
            return newAnswers;
        });
    };

    // Handle submit answers
    const handleSubmit = () => {
        if (frozen) return;

        axios.post('https://api.theodorlundin.se/studian/test/student/submit-answers', {
            'answers': answers
        }, {
            withCredentials: true
        }).catch(error => {
            console.error(error);
        });
    };

    // Handle navigation
    const handleNext = () => {
        if (globalIndex === questions.length - 1 || frozen) return;
        setSwitching(1);
        setGlobalIndex(globalIndex + 1);
        handleSubmit();

        setTimeout(() => {
            setSwitching(0);
        }, 100);
    };

    const handlePrev = () => {
        if (globalIndex === 0 || frozen) return;
        setSwitching(-1);
        setGlobalIndex(globalIndex - 1);
        handleSubmit();

        setTimeout(() => {
            setSwitching(0);
        }, 100)
    };

    const handleDone = () => {
        if (frozen) return;
        axios.put('https://api.theodorlundin.se/studian/test/student/finish-test', {}, {
            withCredentials: true
        }).then(() => {
            window.location.href = '/';
        }).catch(error => {
            console.error(error);
        })
    }

    // Handle freezing
    const freezeModal = () => {
        return (
            <Popup
                modal
                open
                contentStyle={{
                    borderRadius: '1rem',
                    width: 'min(90%, 600px)'
                }}
                // closeOnEscape={false}
                closeOnDocumentClick={false}
            >
                <div className={styles.freezeModalContent}>
                    <img className={styles.illustration} src="/assets/images/graphics/illustrations/ice-cubes.webp" alt="Du är fryst" />
                    <h1>Du är fryst</h1>
                    <span>Det verkar som att du lämnade förhöret. Om det var av misstag kan du be din lärare att släppa in dig igen.</span>
                </div>
            </Popup>
        );
    }

    const handleFreeze = () => {
        // setFrozen(true);
    }

    useEffect(() => {
        window.addEventListener('blur', handleFreeze);
        return () => {
            window.removeEventListener('blur', handleFreeze);
        }
    }, []);

    return (
        <div>
            <Navbar />
            <ContainerSm>
                <div className={styles.container}>
                    <div className={`${styles.question} ${switching === -1 ? styles.previous : switching === 1 ? styles.next : ''}`}>
                        <h1>{ questions[globalIndex]?.title || <Skeleton width={300} /> }</h1>
                        <div className={`${styles.answerWrapper}`}>
                            { answerComponent() }
                        </div>
                    </div>
                    { error && <ErrorMessage message={error} /> }
                    <div className={styles.navigation}>
                        <Popup
                            trigger={<ArrowLeftCircle onClick={handlePrev} />}
                            position="bottom center"
                            on={['hover']}
                            closeOnDocumentClick
                        >
                            <span>Gå tillbaka</span>
                        </Popup>
                        { globalIndex + 1 === questions.length 
                            ? <Popup
                                trigger={<CheckCircle onClick={handleDone} />}
                                position="bottom center"
                                on={['hover']}
                                closeOnDocumentClick
                            >
                                <span>Färdig</span>
                            </Popup>
                            : <Popup
                                trigger={<ArrowRightCircle onClick={handleNext} />}
                                position="bottom center"
                                on={['hover']}
                                closeOnDocumentClick
                            >
                                <span>Nästa</span>
                            </Popup>
                        }
                    </div>
                </div>
            </ContainerSm>
            { frozen && freezeModal() }
        </div>
    );
}

export default Student;