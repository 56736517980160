import styles from './ActionModal.module.scss';
import Popup from 'reactjs-popup';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

const contentStyle = {
    borderRadius: '1rem',
    padding: '2rem',
    width: 'min(90%, 600px)',
};

function ActionModal({ triggerElement, onSubmit, title, content }) {
    return (
        <Popup 
            modal 
            contentStyle={contentStyle} 
            trigger={triggerElement}
        >
            {close => (
                <div className={styles.content}>
                    <h1>{title}</h1>
                    {content}
                    <div className={styles.buttonWrapper}>
                        <SecondaryButton onClick={() => close()}>Avbryt</SecondaryButton>
                        <PrimaryButton onClick={() => { onSubmit(); close(); }}>Rapportera</PrimaryButton>
                    </div>
                </div>
            )}
        </Popup>
    )
}

export default ActionModal;