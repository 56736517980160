import styles from './WelcomeBox.module.scss';
import IconInput from '../../inputs/IconInput';
import { FingerprintCheckCircle } from 'iconoir-react';
import BoxButton from '../../buttons/BoxButton';
import { useState } from 'react';
import ErrorMessage from '../../popups/message/ErrorMessage';

function VerifyEmailBox({ onSubmit, errorMessage, buttonDisabled, loading }) {
    const [ code, setCode ] = useState('');

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit() {
        if (!code) {
            return;
        }
        
        onSubmit(code);
    }

    return (
        <div className={styles.box}>
            <h1 className={styles.title}>Bekräfta mailadress</h1>
            <div className={styles.inputWrapper}>
                <p>Du har fått en fyrsiffrig kod skickad till din mailadress. Kontrollera även skräpposten.</p>
                <IconInput 
                    label='Fyrsiffrig kod' 
                    placeholder='Skriv kod' 
                    type="text" 
                    name="code" 
                    onChange={(e) => setCode(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                    <FingerprintCheckCircle />
                </IconInput>
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <BoxButton onClick={handleSubmit} disabled={buttonDisabled} loading={loading}>Bekräfta</BoxButton>
        </div>
    );
}

export default VerifyEmailBox;
