import SetCardWrapperLg from "../../../sets/cards/SetCardWrapperLg";
import PublicSetCard from "../../../sets/cards/PublicSetCard";
import SearchInput from "../../../inputs/SearchInput";
import { useState, useEffect } from 'react';
import axios from 'axios';
import LazyLoad from "react-lazy-load";
import styles from "../Dashboard.module.scss";

function SetScreen() {
    const [ setIds, setSetIds ] = useState([]);
    const [ renderedSetIds, setRenderedSetIds ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ searchIterationId, setSearchIterationId ] = useState(0);
    
    function retrieveSets() {
        axios.get(`https://api.theodorlundin.se/studian/set/get/by-creator?active=true`, {
            withCredentials: true
        }).then(response => {
            setSetIds(response.data.sets);
            setRenderedSetIds(response.data.sets);
        }).catch(error => {
            console.error('Error with API call:', error.message);
        });
    }

    useEffect(() => {
        retrieveSets();
    }, []);

    // Search sets
    useEffect(() => {
        if (search.length > 0) {
            const filteredIds = [];

            for (let i = 0; i < setIds.length; i++) {
                axios.get(`https://api.theodorlundin.se/studian/set/get/data?set_id=${setIds[i]}`)
                    .then(response => {
                        if (response.data?.set?.name.toLowerCase().includes(search.toLowerCase())) {
                            filteredIds.push(setIds[i]);
                        }

                        if (i === setIds.length - 1) {
                            setRenderedSetIds(filteredIds);
                            setSearchIterationId(Date.now());
                        }
                    })
                    .catch(error => {
                        console.error('Error with API call: ', error.message);
                    });
            }
        } else {
            setRenderedSetIds(setIds);
            setSearchIterationId(Date.now());
        }
    }, [search, setIds]);

    return (
        <>  
            { setIds.length > 0 ? 
                (
                    <>
                        <div style={{ marginBottom: '2rem' }}>
                            <SearchInput placeholder="Sök efter läxa" onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>
                        <SetCardWrapperLg>
                            { renderedSetIds.map((set_id) => <PublicSetCard key={`${set_id}-${searchIterationId}`} setId={set_id} manage={true} onDelete={retrieveSets} />) }
                        </SetCardWrapperLg>
                    </>
                ) : (
                    <div className={styles.noContent}>
                        <LazyLoad className={styles.lazyLoad}>
                            <img src="/assets/images/graphics/illustrations/shrug.png" alt="" />
                        </LazyLoad>
                        <h1>Skapa din första läxa!</h1>
                    </div>
                )
            }
        </>
    );
}

export default SetScreen;