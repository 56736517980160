import { useState, useEffect } from 'react';
import ContainerLg from '../containers/ContainerLg';
import Section from '../containers/Section';
import Navbar from '../components/navbars/Navbar';
import SearchInput from '../components/inputs/SearchInput';
import PublicSetCard from '../components/sets/cards/PublicSetCard';
import SetCardWrapper from '../components/sets/cards/SetCardWrapper';
import axios from 'axios';

// Custom hook for debouncing a value
function useDebounce(value, delay) {
    const [ debouncedValue, setDebouncedValue ] = useState(value);

    useEffect(() => {
    const handler = setTimeout(() => {
        setDebouncedValue(value);
    }, delay);

    return () => {
        clearTimeout(handler);
    };
    }, [value, delay]);

    return debouncedValue;
}
  
function Explore() {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [ searchIterationId, setSearchIterationId ] = useState(0);

    // Using the debounced value to limit API calls
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    useEffect(() => {
        axios.get(`https://api.theodorlundin.se/studian/search/set?query=${debouncedSearchQuery}`)
            .then(response => {
                setSearchResults(response.data.sets);
                setSearchIterationId(Date.now());
            })
            .catch(error => {
                console.error('Error with API call:', error.message);
            });
    }, [debouncedSearchQuery]);    

    function updateSearch(e) {
        setSearchQuery(e.target.value);
    }

    return (
        <div>
            <Navbar />
            <ContainerLg>
                <Section>
                    <SearchInput placeholder="Sök efter läxa, ämne, ord..." onChange={updateSearch} />
                </Section>
                    <span>{ searchQuery === '' && 'Senaste läxorna' }</span>
                    <span>{ searchQuery !== '' && `Visar resultat för: ${searchQuery}` }</span>
                <Section>
                    {searchResults.length > 0 &&
                        <SetCardWrapper>
                            { searchResults.map((set_id) => <PublicSetCard key={`${set_id}-${searchIterationId}`} setId={set_id} />) }
                        </SetCardWrapper>
                    }
                </Section>
            </ContainerLg>
        </div>
    );
}

export default Explore;