import ContainerSm from '../containers/ContainerSm';
import Section from '../containers/Section';
import Navbar from '../components/navbars/Navbar';
import MainFooter from '../components/footers/MainFooter';
import HeadingXxl from '../components/text/headings/HeadingXxl';
import HeadingLg from '../components/text/headings/HeadingLg';
import NormalParagraph from '../components/text/paragraphs/NormalParagraph';

function Integrity() {
    return (
        <div>
            <Navbar />
            <ContainerSm>
                <Section>
                    <HeadingXxl>Integritetspolicy</HeadingXxl>
                    <HeadingLg>Introduktion</HeadingLg>
                    <NormalParagraph>
                        Välkommen till Studian! Vi värnar om din integritet och säkerhet när du använder vår webbapp. Denna integritetspolicy förklarar hur vi samlar in, använder och skyddar dina personuppgifter samt vilka rättigheter du har enligt GDPR.
                    </NormalParagraph>
                    <HeadingLg>Insamlade Personuppgifter</HeadingLg>
                    <NormalParagraph>
                        Vi samlar in följande typer av personuppgifter från våra registrerade användare:
                        <ul>
                            <li>Mailadress</li>
                            <li>Användarnamn</li>
                            <li>Lösenord</li>
                        </ul>
                    </NormalParagraph>
                    <HeadingLg>Syfte med Insamling av Personuppgifter</HeadingLg>
                    <NormalParagraph>
                        Vi samlar in dessa personuppgifter för att:
                        <ul>
                            <li>Ge användarna tillgång till hemsidans alla funktioner.</li>
                            <li>Skicka mailutskick.</li>
                            <li>Utföra intern statistik.</li>
                        </ul>
                    </NormalParagraph>
                    <HeadingLg>Lagring av Personuppgifter</HeadingLg>
                    <NormalParagraph>
                        Vi behåller personuppgifterna under en odefinierad tid, oftast tills användarna själva väljer att avsluta sina konton. Personuppgifter lagras också i upp till ett år efter att kontot har avslutats, om det inte finns särskilda skäl för att lagra dem längre.
                    </NormalParagraph>
                    <HeadingLg>Delning av Personuppgifter med Tredjepart</HeadingLg>
                    <NormalParagraph>
                        Vi delar i vissa fall personuppgifter med tredje parter, t.ex. vid hantering av mailutskick. Vi kan också dela personuppgifter när det krävs enligt lag.
                    </NormalParagraph>
                    <HeadingLg>Säkerhet för Personuppgifter</HeadingLg>
                    <NormalParagraph>
                        Vi vidtar både tekniska och administrativa åtgärder för att skydda personuppgifter. Lösenord krypteras för att säkerställa att de är skyddade. All hantering av uppgifterna på våra servrar sker under säkerhetskontrollerade förhållanden. Vi har också en tydlig incidenthanteringsplan och kommer att informera användarna om eventuella dataintrång eller incidenter som påverkar deras personuppgifter samt de åtgärder vi vidtar för att hantera sådana situationer.
                    </NormalParagraph>
                    <HeadingLg>Användarnas Rättigheter</HeadingLg>
                    <NormalParagraph>
                        Enligt GDPR har du rätt att:
                        <ul>
                            <li>Begära tillgång till dina personuppgifter.</li>
                            <li>Begära rättelse av felaktiga eller ofullständiga uppgifter.</li>
                            <li>Begära radering av dina personuppgifter.</li>
                            <li>Begära begränsning av behandlingen av dina personuppgifter.</li>
                            <li>Invända mot behandlingen av dina personuppgifter.</li>
                            <li>Begära dataportabilitet.</li>
                        </ul>
                        För att utöva någon av dessa rättigheter, vänligen kontakta oss på integritet@studian.se.
                    </NormalParagraph>
                    <HeadingLg>Kontaktinformation</HeadingLg>
                    <NormalParagraph>
                    För frågor om denna integritetspolicy eller andra integritetsrelaterade frågor, vänligen kontakta oss påintegritet@studian.se. Vid supportrelaterade frågor, vänligen kontakta oss på support@studian.se.
                    </NormalParagraph>
                </Section>
            </ContainerSm>
            <MainFooter />
        </div>
    );
}

export default Integrity;