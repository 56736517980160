import Navbar from "../components/navbars/Navbar";
import Sidebar from "../components/pages/dashboard/Sidebar";
import DashboardContentWrapper from "../components/pages/dashboard/DashboardContentWrapper";
import { Routes, Route, useLocation } from "react-router-dom";
import SetScreen from "../components/pages/dashboard/screens/SetScreen";
import TestScreen from "../components/pages/dashboard/screens/TestScreen";
import Auth from "../components/auth/Auth";

// TODO: ARIA labels for screen readers
// TODO: Make sidebar collapsable
// TODO: Keyboard navigation

function Dashboard() {
    const location = useLocation();
    const page = location.pathname.split('/')[2] || 'sets';

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <Sidebar selected={page} />
                <DashboardContentWrapper>
                    <Routes>
                        <Route path="/" element={<SetScreen />} />
                        <Route path="/sets" element={<SetScreen />} />
                        <Route path="/tests" element={<TestScreen />} />
                    </Routes>
                </DashboardContentWrapper>
            </Auth>
        </div>
    );
}

export default Dashboard;