import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import styles from './DeleteSetModal.module.scss';
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";
import { Trash, WarningTriangle } from "iconoir-react/regular";
import ErrorMessage from "../../../popups/message/ErrorMessage";
import axios from 'axios';
import { useState } from 'react';

const contentStyle = {
    borderRadius: '1rem',
    padding: '2rem',
    width: 'min(90%, 500px)',
}

function DeleteSetModal({ setId, className, onDelete }) {
    const [ error, setError ] = useState(null);

    function deleteSet(closeModal) {
        axios.delete(`https://api.theodorlundin.se/studian/set/manage/delete?set_id=${setId}`, {
            withCredentials: true
        }).then(() => {
            closeModal();
            onDelete();
        }).catch(error => {
            const errorMessage = error.response?.data?.message || 'Något gick fel. Kontakta oss om felet kvarstår.';
            setError(errorMessage);
            console.error('Error with API call: ', errorMessage);
        });
    }

    return (
        <Popup 
            modal 
            trigger={
                <span className={className}>
                    <Trash />
                    Ta bort
                </span>
            } 
            position="bottom center"
            {...{contentStyle}}
        >
            { close => (
                <div className={styles.content}>
                    <WarningTriangle className={styles.icon} />
                    <h1>Ta bort läxan?</h1>
                    <p>Läxan tas bort permanent och går inte att få tillbaka.</p>
                    { error && <ErrorMessage message={error} /> }
                    <div className={styles.buttonWrapper}>
                        <SecondaryButton onClick={() => { close() }}>Nej</SecondaryButton>
                        <PrimaryButton onClick={() => { deleteSet(close) }}>Ja, ta bort</PrimaryButton>
                    </div>
                </div>
            ) }
        </Popup>
    );
}

export default DeleteSetModal;