import { useState } from 'react';
import ActionModal from '../../popups/modals/ActionModal';
import { WarningTriangle } from 'iconoir-react';
import Select from '../../inputs/Select';
import axios from 'axios';

function ReportModal({ triggerClassName, setId }) {
    const [ reason, setReason ] = useState('');

    function handleReport() {
        axios.post('https://api.theodorlundin.se/studian/moderation/report-set', {
            set_id: setId,
            reason: reason
        }).then(res => {
            console.log(res);
        }).catch(error => {
            console.error('Error with API call: ', error);
        });
    }

    return (
        <ActionModal
            triggerElement={
                <span className={triggerClassName}><WarningTriangle /></span>
            }
            onSubmit={handleReport}
            title="Rapportera"
            content={
                <>
                    <p>När du valt anledning och rapporterat innehållet granskar vi det. Bryter det mot våra riktlinjer tas innehållet bort.</p>
                    <Select value={reason} onChange={e => setReason(e.target.value)}>
                        <option value="0">Välj en anledning</option>
                        <option value="Stötande språk eller hat">Stötande språk eller hat</option>
                        <option value="Trakasserier eller mobbning">Trakasserier eller mobbning</option>
                        <option value="Explicit eller olämpligt innehåll">Explicit eller olämpligt innehåll</option>
                        <option value="Spam eller bedrägerier">Spam eller bedrägerier</option>
                        <option value="Desinformation">Desinformation</option>
                        <option value="Intrång i privatlivet">Intrång i privatlivet</option>
                        <option value="Upphovsrättsintrång">Upphovsrättsintrång</option>
                    </Select>
                </>
            }
        />
    )
}

export default ReportModal;