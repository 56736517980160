import styles from './NormalInput.module.scss';
import { useState, useEffect } from 'react';

function NormalInput({ placeholder, label, type, name, onChange, onKeyDown, onKeyUp, value = '', autoFocus = false }) {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    function handleChange(e) {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (onChange) {
            onChange(e);
        }
    }

    let labelElement = (
        <label className={styles.label} htmlFor={name}>{label}</label>
    );

    return (
        <div className={styles.inputWrapper}>
            {label && labelElement}
            <input
                type={type}
                placeholder={placeholder}
                name={name}
                className={styles.input}
                onChange={e => handleChange(e)}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                value={inputValue}
                autoFocus={autoFocus}
            />
        </div>
    );
}

export default NormalInput;