import { useEffect, useState } from 'react';
import styles from './Flashcards.module.scss';
import { Shuffle, RefreshDouble, NavArrowLeft, NavArrowRight, ShareIos, Link } from 'iconoir-react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import UserLink from '../../components/user/UserLink';
import Menu from '../../components/popups/menus/Menu';
import ReportModal from '../../components/sets/misc/ReportModal';
import SetMethodsNav from '../../components/pages/set/SetMethodsNav';

function Flashcards({ setId, setData }) {
    const [ terms, setTerms ] = useState(null);
    const [ finalTerms, setFinalTerms ] = useState(null);
    const [ termIndex, setTermIndex ] = useState(0);
    const [ side, setSide ] = useState(0);
    const [ flipping, setFlipping ] = useState(false);
    const [ nexting, setNexting ] = useState(false);
    const [ preving, setPreving ] = useState(false);
    const [ shuffled, setShuffled ] = useState(false);
    const [ inverted, setInverted ] = useState(false);
    const [ image, setImage ] = useState(null);

    useEffect(() => {
        if (!setData) return;
        const mappedTerms = setData.content.map(pair => {
            return { 
                term: pair['term'], 
                definition: pair['definition'],
                termImage: pair['term_image'],
                definitionImage: pair['definition_image']
            };
        });
        setTerms(mappedTerms);
    }, [setData]);

    useEffect(() => {
        if (!terms) return;
        setFinalTerms(terms);
    }, [terms]);

    // Flip
    function handleFlip() {
        if (flipping) return;

        setFlipping(true);

        setTimeout(() => {
            setSide(side === 0 ? 1 : 0);
        }, 175);

        setTimeout(() => {
            setFlipping(false);
        }, 350);
    }

    // Navigation
    function handleNext() {
        if (termIndex === terms.length - 1) return;
        if (preving || nexting) return;

        setTermIndex(termIndex + 1);
        setSide(inverted ? 1 : 0);
        setNexting(true);
        setTimeout(() => {
            setNexting(false);
        }, 150);
    }

    function handlePrev() {
        if (termIndex === 0) return;
        if (preving || nexting) return;
        
        setTermIndex(termIndex - 1);
        setSide(inverted ? 1 : 0);
        setPreving(true);
        setTimeout(() => {
            setPreving(false);
        }, 150);
    }
    
    // Shuffle
    function handleShuffle() {
        if (!terms) return;
        
        if (!shuffled) {
            const shuffledTerms = shuffleArray(terms);
            setFinalTerms(shuffledTerms);
        } else {
            setFinalTerms(terms);
        }

        setShuffled(!shuffled);
    }

    function shuffleArray(array) {
        let returnArray = [...array];
        let currentIndex = returnArray.length;

        while (currentIndex !== 0) {
            const randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [returnArray[currentIndex], returnArray[randomIndex]] = [returnArray[randomIndex], returnArray[currentIndex]];
        }

        return returnArray;
    }

    // Invert
    function handleInvert() {
        if (inverted && side === 1) {
            handleFlip();
        } else if (!inverted && side === 0) {
            handleFlip();
        }

        setInverted(!inverted);
    }

    // Keyboard
    function handleKeyDown(event) {
        if (event.key === ' ' || event.code === 'Space') {
            event.preventDefault();
            handleFlip();
        } else if (event.key === 'ArrowRight') {
            event.preventDefault();
            handleNext();
        } else if (event.key === 'ArrowLeft') {
            event.preventDefault();
            handlePrev();
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });

    // Share set
    function copyLink(closeMenu) {
        const link = `https://studian.se/set/${setId}`;
        navigator.clipboard.writeText(link);
        closeMenu();
    }

    // Get images
    useEffect(() => {
        if (!finalTerms) return;
        setImage(null);

        if (side === 0 && finalTerms[termIndex]['termImage'] !== null) {
            const url = finalTerms[termIndex]['termImage'];
            setImage(
                <img className={styles.image} src={url} alt='' />
            );
        } else if (side === 1 && finalTerms[termIndex]['definitionImage'] !== null) {
            const url = finalTerms[termIndex]['definitionImage'];
            setImage(
                <img className={styles.image} src={url} alt='' />
            );
        }
    }, [finalTerms, side, termIndex]);

    return (
        <div className={styles.flashcards}>
            <div className={styles.main}>
                <SetMethodsNav setId={setId} selected='flashcards' />
                <div className={styles.container} onKeyDown={handleKeyDown}>
                    <div className={`${styles.flashcard} ${flipping ? styles.flipping : ''} ${nexting ? styles.nexting : ''} ${preving ? styles.preving : ''}`} onClick={handleFlip}></div>
                    { terms
                        ? <div className={`${styles.flashcardContent} ${flipping ? styles.flippingTerm : ''} ${nexting ? styles.nextingTerm : ''} ${preving ? styles.previngTerm : ''}`}>
                            { finalTerms && image }
                            <span className={`${styles.term}`}>
                                {finalTerms ? finalTerms[termIndex][side === 0 ? 'term' : 'definition'] : ''}
                            </span>
                        </div>
                        : <Skeleton width={300} height={50} />
                    }
                </div>
                <div className={styles.ad}></div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerNav}>
                    <div className={styles.navSection}>
                        <span className={`${styles.item} ${shuffled ? styles.active : ''}`} onClick={handleShuffle}><Shuffle /></span>
                        <span className={`${styles.item} ${inverted ? styles.active : ''}`} onClick={handleInvert}><RefreshDouble /></span>
                    </div>
                    <div className={styles.navSection}>
                        <div className={styles.flashcardsNav}>
                            <span className={styles.item} onClick={handlePrev}><NavArrowLeft /></span>
                            <span className={styles.label}>{termIndex + 1} / {terms ? terms.length : 0}</span>
                            <span className={styles.item} onClick={handleNext}><NavArrowRight /></span>
                        </div>
                    </div>
                    <div className={styles.navSection}>
                        <ReportModal setId={setId} triggerClassName={styles.item} />
                        <Menu 
                            triggerElement={
                                <span className={styles.item}><ShareIos /></span>
                            }
                            items={[
                                { label: 'Kopiera länk', icon: <Link />, onClick: copyLink }
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.creator}>
                { setData
                    ? <UserLink userId={setData.creator} />
                    : <Skeleton width={150} height={48} />
                }
            </div>
            <div className={styles.summary}>
                <h2 className={styles.title}>{ terms ? terms.length : 0 } termer</h2>
                { terms
                    ? terms.map(term => {
                        return (
                            <div className={styles.term} key={term.term + term.defintion}>
                                <span>{term.term}</span>
                                <span>{term.definition}</span>
                            </div>
                        );
                    })
                    : <Skeleton width={'100%'} height={50} />
                }
            </div>
        </div>

    );
}

export default Flashcards;