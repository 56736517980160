import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import styles from './Pfp.module.scss';

function PfpSmall({ username }) {
    const [ profilePicture, setProfilePicture ] = useState(null);
    const [ loading, setLoading ] = useState(null);

    const url = username ? 
        `https://api.theodorlundin.se/studian/user/get-profile-picture?username=${username}&size=32` :
        'https://api.theodorlundin.se/studian/user/get-profile-picture?active=true&size=32';

    useEffect(() => {
        axios.get(url, {
                withCredentials: true 
            })
            .then(response => {                
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(response.data.xml, 'application/xml');

                const svgElement = xmlDoc.documentElement;
                setProfilePicture(svgElement.outerHTML);
                setLoading(false);
            })
            .catch(error => {
                setLoading(true);
            })
    }, [url])

    return (
        <>
            {loading ? (
                <Skeleton width={32} height={32} circle={true} />
            ) : (
                <div className={styles.pfp} dangerouslySetInnerHTML={{ __html: profilePicture }} />
            )}
        </>
    );
}

export default PfpSmall;