import styles from './WelcomeBox.module.scss';
import IconInput from '../../inputs/IconInput';
import { AtSign } from 'iconoir-react';
import BoxButton from '../../buttons/BoxButton';
import { useState } from 'react';
import ErrorMessage from '../../popups/message/ErrorMessage';
import SuccessMessage from '../../popups/message/SuccessMessage';

function ForgotPasswordBox({ onSubmit, errorMessage, successMessage, buttonDisabled, loading }) {
    const [ email, setEmail ] = useState('');

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit() {
        if (!email) {
            return;
        }

        onSubmit(email);
    }

    return (
        <div className={styles.box}>
            <h1 className={styles.title}>Glömt lösenord</h1>
            <div className={styles.inputWrapper}>
                <p>Ange din mailadress. Du kommer sedan få en länk där du kan ange ett nytt lösenord. Kontrollera även skräpposten!</p>
                <IconInput 
                    label='Mailadress' 
                    placeholder='Skriv mailadress' 
                    type="email" 
                    name="email" 
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                    <AtSign />
                </IconInput>
            </div>
            { successMessage && <SuccessMessage message={successMessage} /> }
            { errorMessage && <ErrorMessage message={errorMessage} /> }
            <span onClick={handleSubmit} className={styles.link}>Klicka här för ny länk</span>
            <BoxButton onClick={handleSubmit} disabled={buttonDisabled} loading={loading}>Skicka länk</BoxButton>
        </div>
    );
}

export default ForgotPasswordBox;
