import styles from './TermCard.module.scss';
import { Trash, MediaImagePlus, Xmark } from 'iconoir-react';
import { useRef, useState } from 'react';

function TermCard({ term = '', definition = '', termImage = null, definitionImage = null, ssid, index, onDragStart, onDragOver, onDrop, onDragEnd, onDelete, onChange }) {
    const cardRef = useRef(null);
    const [ deleting, setDeleting ] = useState(false);
    const [ termValue, setTermValue ] = useState(term);
    const [ definitionValue, setDefinitionValue ] = useState(definition);
    const [ termImageValue, setTermImageFile ] = useState(termImage);
    const [ definitionImageValue, setDefinitionImageFile ] = useState(definitionImage);

    const isFile = (value) => value instanceof File;

    const handleTermChange = (e) => {
        const newTermValue = e.target.value;
        setTermValue(newTermValue);
        onChange(ssid, newTermValue, definitionValue);
    };

    const handleTermImageChange = (e) => {
        const newTermImage = e.target.files[0];
        setTermImageFile(newTermImage);
        onChange(ssid, termValue, definitionValue, newTermImage, definitionImage);
    };

    const handleDefinitionChange = (e) => {
        const newDefinitionValue = e.target.value;
        setDefinitionValue(newDefinitionValue);
        onChange(ssid, termValue, newDefinitionValue);
    };

    const handleDefinitionImageChange = (e) => {
        const newDefinitionImage = e.target.files[0];
        setDefinitionImageFile(newDefinitionImage);
        onChange(ssid, termValue, definitionValue, termImage, newDefinitionImage);
    };

    const handleImageRemoval = (imageType) => {
        if (imageType === 'termImage') {
            setTermImageFile(null);
            onChange(ssid, termValue, definitionValue, null, definitionImageValue);
        } else if (imageType === 'definitionImage') {
            setDefinitionImageFile(null);
            onChange(ssid, termValue, definitionValue, termImageValue, null);
        }
    };

    return (
        <div
            className={`${styles.card} ${deleting ? styles.deleting : ''}`}
            data-ssid={ssid}
            ref={cardRef}
            onDragOver={(e) => onDragOver(e, ssid)}
            onDrop={(e) => {
                onDrop(e, ssid);
                cardRef.current.classList.remove(styles.dragging);
            }}
            onDragEnd={() => {
                onDragEnd();
                cardRef.current.classList.remove(styles.dragging);
            }}
        >
            <div
                className={styles.header}
                draggable
                onDragStart={(e) => {
                    onDragStart(e, ssid);
                    cardRef.current.classList.add(styles.dragging);
                }}
            >
                <span className={styles.ssid}>#{index}</span>
                <Trash 
                    onClick={() => {
                        onDelete(ssid);
                        setDeleting(true);
                    }}
                />
            </div>
            <div className={styles.body}>
                <div className={styles.term}>
                    <input 
                        type="text" 
                        placeholder='Skriv term' 
                        value={termValue}
                        onChange={handleTermChange} 
                        className={styles.termInput}
                    />
                    {  termImageValue && (
                        <div className={styles.imagePreview}>
                            <img 
                                src={isFile(termImageValue) ? URL.createObjectURL(termImageValue) : termImageValue} 
                                alt={termValue ?? 'term'}
                                className={styles.image} 
                            />
                            <Xmark className={styles.removeImage} onClick={() => {handleImageRemoval('termImage')}} />
                        </div>
                    ) }
                    { !termImageValue && (
                        <label className={styles.fileInput}>
                            <input type="file" accept="image/*" onChange={handleTermImageChange} />
                            <MediaImagePlus />
                        </label>
                    ) }
                </div>
                <div className={styles.term}>
                    <input 
                        type="text" 
                        placeholder='Skriv definition' 
                        value={definitionValue}
                        onChange={handleDefinitionChange} 
                        className={styles.termInput}
                    />
                    {  definitionImageValue !== null && (
                        <div className={styles.imagePreview}>
                            <img 
                                src={isFile(definitionImageValue) ? URL.createObjectURL(definitionImageValue) : definitionImageValue} 
                                alt={definitionValue ?? 'definition'} 
                                className={styles.image} 
                            />
                            <Xmark className={styles.removeImage} onClick={() => {handleImageRemoval('definitionImage')}} />
                        </div>
                    ) }
                    { !definitionImageValue && (
                        <label className={styles.fileInput}>
                            <input type="file" accept="image/*" onChange={handleDefinitionImageChange} />
                            <MediaImagePlus />
                        </label>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default TermCard;