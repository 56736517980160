import Navbar from '../../components/navbars/Navbar';
import GradientContainer from '../../containers/GradientContainer';
import NewPasswordBox from '../../components/boxes/welcome/NewPasswordBox';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

function NewPassword() {
    const [ error, setError ] = useState(null);
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ searchParams ] = useSearchParams(false);
    const [ authId, setAuthId ] = useState(null);
    
    useEffect(() => {
        setAuthId(searchParams.get('auth'));
    }, [searchParams]);

    async function handleSubmit(password) {
        setButtonDisabled(true);
        setLoading(true);

        setTimeout(() => {
            axios.post('https://api.theodorlundin.se/studian/auth/change-password', {
                auth_id: authId,
                password: password,
            }, {
                withCredentials: true
            }).then(() => {
                window.location.href = '/login';
            }).catch(error => {
                const message = error.response?.data?.error || 'Något gick fel. Försök igen eller kontakta oss.';
                setError(message);
                setButtonDisabled(false);
                setLoading(false);
            });
        }, 1000);
    }

    return (
        <div>
            <Navbar />
            <GradientContainer centerContent="true">
                <NewPasswordBox onSubmit={handleSubmit} errorMessage={error} buttonDisabled={buttonDisabled} loading={loading} />
            </GradientContainer>
        </div>
    );
}

export default NewPassword;