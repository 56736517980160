import styles from './Shortcut.module.scss';

function Shortcut({ shortcutTitle, shortcutColor, onClick }) {
    // Background color
    const colorNames = ['blue', 'green', 'orange', 'pink'],
          colorValues = ['#9edaff', '#94ffb6', '#ffe76e', '#ffb8f4'],
          textValues = ['#0d6394', '#00872b', '#94650d', '#b30498'];
    
    if (!colorNames.includes(shortcutColor)) shortcutColor = 'blue';

    const backgroundColor = colorValues[colorNames.indexOf(shortcutColor)];
    const textColor = textValues[colorNames.indexOf(shortcutColor)];

    // Component
    return (
        <div
            className={styles.shortcut}
            style={{ backgroundColor: backgroundColor, color: textColor }}
            onClick={onClick}
        >
            <img src="/assets/images/graphics/shortcut-pattern.svg" alt="Genväg" className={styles.pattern} draggable="false" />
            <span className={styles.title}>{shortcutTitle}</span>
        </div>
    );
}

export default Shortcut;