import styles from './ProfileMenu.module.scss';
import PfpMedium from '../../user/PfpMedium';
import { GridPlus, UserBadgeCheck, ChatBubble, PlusCircle, BookLock, Cookie, ClipboardCheck, Settings, LogOut } from 'iconoir-react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function ProfileMenu({ open }) {
    const [ username, setUsername ] = useState(null);
    const [ email, setEmail ] = useState(null);

    useEffect(() => {
        axios.get('https://api.theodorlundin.se/studian/user/get-user-data?active=true', {
            withCredentials: true
        })
        .then(response => {
            setUsername(response.data.user_data.username);
            setEmail(response.data.user_data.email);
        })
        .catch(error => {
            const message = error.response?.data?.error || 'Något gick fel. Försök igen eller kontakta oss.';
            console.error(message);
        })
    }, []);

    return (
        <div className={`${styles.menu} ${open ? styles.open : ''}`}>
            <div className={styles.header}>
                <PfpMedium />
                <div className={styles.user}>
                    <span className={styles.username}>{
                        username ? username :
                        <Skeleton width={70} />
                    }</span>
                    <span className={styles.email}>{
                        email ? email :
                        <Skeleton width={120} />
                    }</span>
                </div>
            </div>
            <div className={styles.section}>
                <a href="/dashboard" className={styles.item}>
                    <GridPlus />
                    <span className={styles.label}>Dashboard</span>
                </a>
            </div>
            <div className={styles.section}>
                <a href="/chat" className={styles.item}>
                    <ChatBubble />
                    <span className={styles.label}>Meddelanden</span>
                </a>
                <a href={'/user/' + username} className={styles.item}>
                    <UserBadgeCheck />
                    <span className={styles.label}>Offentlig profil</span>
                </a>
            </div>
            <div className={styles.section}>
                <a href="/create/set" className={styles.item}>
                    <PlusCircle />
                    <span className={styles.label}>Skapa läxa</span>
                </a>
                <a href="/new/test" className={styles.item}>
                    <PlusCircle />
                    <span className={styles.label}>Skapa läxförhör</span>
                </a>
            </div>
            <div className={styles.section}>
                <a href="/integrity" className={styles.item}>
                    <BookLock />
                    <span className={styles.label}>Integritet</span>
                </a>
                <a href="/" className={styles.item}>
                    <Cookie />
                    <span className={styles.label}>Cookies</span>
                </a>
                <a href="/conditions" className={styles.item}>
                    <ClipboardCheck />
                    <span className={styles.label}>Villkor och riktlinjer</span>
                </a>
            </div>
            <div className={styles.section}>
                <a href="/settings" className={styles.item}>
                    <Settings />
                    <span className={styles.label}>Inställningar</span>
                </a>
                <a href="/logout" className={styles.item}>
                    <LogOut />
                    <span className={styles.label}>Logga ut</span>
                </a>
            </div>
        </div>
    )
}

export default ProfileMenu;