import styles from './Set.module.scss';
import { useParams } from "react-router-dom";
import Navbar from "../../components/navbars/Navbar";
import { useState, useEffect } from "react";
import axios from "axios";
import ContainerLg from "../../containers/ContainerLg";
import Section from '../../containers/Section';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import StarRating from '../../components/sets/misc/StarRating';
import MainFooter from '../../components/footers/MainFooter';
import Flashcards from './Flashcards';
import Write from './Write';
import Match from './Match';
import RatePopup from '../../components/sets/misc/RatePopup';

function Set() {
    const { set_id, type } = useParams();
    const [ setData, setSetData ] = useState(null);

    useEffect(() => {
        axios.get(`https://api.theodorlundin.se/studian/set/get/data?set_id=${set_id}`)
        .then(response => {
            setSetData(response.data.set);
        }).catch(error => {
            console.error('Error with API call: ', error.message);
        });
    }, [ set_id ]);

    // Render page by type
    function renderPageComponentByType() {
        switch (type) {
            case 'flashcards':
                return <Flashcards setId={set_id} setData={setData} />;
            
            case 'write':
                return <Write setId={set_id} setData={setData} />;

                
            case 'match':
                return <Match setId={set_id} setData={setData} />;
            
            default:
                return <Flashcards setId={set_id} setData={setData} />;
        }
    }

    return (
        <div>
            <Navbar />
            <ContainerLg>
                <Section>
                    <div className={styles.set}>
                        <div className={styles.top}>
                            { setData 
                                ? <span className={styles.subject}>{ setData.subject }</span>
                                : <Skeleton width={100} />
                            }
                            <StarRating setId={set_id} />
                            { setData 
                                ? <RatePopup setId={set_id} />
                                : <Skeleton width={120} />
                            }
                        </div>
                        { setData
                            ? <span className={styles.name}>{ setData.name }</span>
                            : <Skeleton width={300} height={50} />
                        }
                        { renderPageComponentByType() }
                    </div>
                </Section>
            </ContainerLg>
            <MainFooter />
        </div>
    );
}

export default Set;