import React from 'react';
import styles from './SecondaryButton.module.scss';

const SecondaryButton = React.forwardRef(({ children, onClick, disabled = false }, ref) => {
    return (
        <button ref={ref} className={styles.button} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    )
})

export default SecondaryButton;