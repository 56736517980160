import styles from './WelcomeBox.module.scss';
import IconInput from '../../inputs/IconInput';
import { AtSign, User, Lock } from 'iconoir-react';
import BoxButton from '../../buttons/BoxButton';
import Checkbox from '../../inputs/Checkbox';
import ErrorMessage from '../../popups/message/ErrorMessage';
import { useState } from 'react';

function SignUpBox({ onSubmit, errorMessage, buttonDisabled, loading }) {
    const [ email, setEmail ] = useState('');
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ acceptTerms, setAcceptTerms ] = useState(false);

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit() {
        if (!email || !username || !password) {
            return;
        }
        
        onSubmit(email, username, password, acceptTerms);
    }

    return (
        <div className={styles.box}>
            <h1 className={styles.title}>Skapa konto</h1>
            <div className={styles.inputWrapper}>
                <IconInput 
                    label='Mailadress' 
                    placeholder='Skriv mailadress' 
                    type="email" 
                    name="email"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setEmail(e.target.value)}
                >
                    <AtSign />
                </IconInput>
                <IconInput 
                    label='Användarnamn' 
                    placeholder='Skapa användarnamn' 
                    type="text" 
                    name="username"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setUsername(e.target.value)}
                >
                    <User />
                </IconInput>
                <IconInput 
                    label='Lösenord' 
                    placeholder='Skapa lösenord' 
                    type="password" 
                    name="password"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setPassword(e.target.value)}
                >
                    <Lock />
                </IconInput>
            </div>
            <span className={styles.checkbox}>
                <Checkbox onChange={(e) => setAcceptTerms(e.target.checked)}>
                    Jag godkänner <a href="/conditions" target="_blank">Villkor</a> och <a href="/integrity" target="_blank">Integritetspolicy</a>
                </Checkbox>
            </span>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <BoxButton onClick={handleSubmit} disabled={buttonDisabled} loading={loading}>Skapa konto</BoxButton>
            <a href="/login" className={styles.footer}>Har du ett konto? <i>Logga in!</i></a>
        </div>
    );
}

export default SignUpBox;