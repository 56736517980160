import styles from './NormalParagraph.module.scss';

function NormalParagraph({ children }) {
    return (
        <p className={styles.paragraph}>
            {children}
        </p>
    );
}

export default NormalParagraph;