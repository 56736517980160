import styles from './StarRating.module.scss';
import { StarSolid } from 'iconoir-react';
import { useState, useEffect } from 'react';
import axios from 'axios';

function StarRating({ setId }) {
    const [ rating, setRating ] = useState(0);

    useEffect(() => {
        axios.get(`https://api.theodorlundin.se/studian/set/rating/set-average?set_id=${setId}`)
        .then(response => {
            if (response.data.rating > 0) {
                setRating(response.data.rating);
            }
        }).catch(error => {
            console.error('Error with API call: ', error);
        })
    }, [setId]);

    return (
        <div className={styles.rating}>
            <StarSolid className={rating >= 1 ? styles.filled : ''} />
            <StarSolid className={rating >= 2 ? styles.filled : ''} />
            <StarSolid className={rating >= 3 ? styles.filled : ''} />
            <StarSolid className={rating >= 4 ? styles.filled : ''} />
            <StarSolid className={rating >= 5 ? styles.filled : ''} />
        </div>
    );
}

export default StarRating;