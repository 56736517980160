import styles from './PublicSetCard.module.scss';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axios from 'axios';
import PfpSmall from '../../user/PfpSmall';
// import { hex, rgb } from 'color-convert/conversions';
import { EditPencil } from 'iconoir-react';
import DeleteSetModal from './popups/DeleteSetModal';
import ShareSetMenu from './popups/ShareSetMenu';

function PublicSetCard({ setId, manage, onDelete }) {
    const [ setData, setSetData ] = useState(null);

    useEffect(() => {
        axios.get(`https://api.theodorlundin.se/studian/set/get/data?set_id=${setId}`)
            .then(response => {
                setSetData(response.data.set);
            })
            .catch(error => {
                console.error('Error with API call: ', error.message);
            });
    }, [ setId ]);


    const publicFooter = (
        <div className={styles.creator}>
            { setData ? <PfpSmall username={setData.creator_name} /> : <Skeleton width={32} height={32} circle="true" /> }
            <span className={styles.username}>{ setData ? setData.creator_name : <Skeleton width={70} /> }</span>
        </div>
    );

    const manageFooter = (
        <div className={styles.manage}>
            <a href={`/edit/set/${setId}`} className={styles.item}>
                <EditPencil />
                Redigera
            </a>
            <DeleteSetModal setId={setId} className={styles.item} onDelete={onDelete} />
            <span className={styles.item}>
                <ShareSetMenu />
            </span>
        </div>
    );

    return (
        <div 
            className={styles.card} 
            onClick={() => window.location.href = `/set/${setId}`} 
            tabIndex={0}
        >
            <div className={styles.header}>
                <span className={styles.title}>{ setData ? setData.name : <Skeleton width={150} /> }</span>
                { setData ? <span className={styles.subject}>{ setData.subject }</span> : <Skeleton width={70} /> }
            </div>
            <span className={styles.subtitle}>{ setData ? `${setData.term_number} termer` : <Skeleton width={70} /> }</span>
            { manage ? manageFooter : publicFooter }
        </div>
    );
}

export default PublicSetCard;