import styles from './FeatureSection.module.scss';

function FeatureSection({ children, title, imgSrc, inverted }) {
    let imageElement = <img src={imgSrc} alt={title} className={styles.image} />;

    return (
        <section className={styles.wrapper}>
            {inverted !== 'true' && imageElement}
            <div>
                <h1 className={styles.title}>{title}</h1>
                {children}
            </div>
            {inverted === 'true' && imageElement}
        </section>
    );
}

export default FeatureSection;