import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import { useState } from 'react';
import styles from './ImportTermsModal.module.scss'; // Create a SCSS file for styling
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";
import NormalInput from "../../../inputs/NormalInput";
import { Import } from "iconoir-react";

const contentStyle = {
    borderRadius: '1rem',
    padding: '2rem',
    width: 'min(90%, 600px)',
};

function ImportTermsModal({ onImport }) {
    const [text, setText] = useState('');
    const [termSeparator, setTermSeparator] = useState(' - '); // Default to tab for term-definition separator
    const [pairSeparator, setPairSeparator] = useState('ny-rad'); // Default to newline for pair separator

    const handleImport = () => {
        const actualPairSeparator = pairSeparator === 'ny-rad' ? '\n' : pairSeparator;

        const termPairs = text.split(actualPairSeparator).map(pair => {
            const [term, definition] = pair.split(termSeparator);
            return { term: term?.trim(), definition: definition?.trim() };
        });

        onImport(termPairs);
        setText('');
        setTermSeparator(' - ');
        setPairSeparator('ny-rad');
    };

    return (
        <Popup 
            modal 
            contentStyle={contentStyle} 
            trigger={
                <SecondaryButton>
                    <Import />
                    Importera termer
                </SecondaryButton>
            }
        >
            {close => (
                <div className={styles.content}>
                    <h1>Importera termer</h1>
                    <textarea
                        value={text}
                        onChange={e => setText(e.target.value)}
                        className={styles.textArea}
                        placeholder="Klistra in text här..."
                    />
                    <div className={styles.separatorInputs}>
                        <NormalInput 
                            type="text"
                            label="Separatör mellan termpar"
                            value={termSeparator}
                            onChange={e => setTermSeparator(e.target.value)}
                            placeholder="ex. komma, kolon eller bindestreck"
                        />
                        <NormalInput 
                            type="text"
                            label="Separatör mellan term och definition"
                            value={pairSeparator}
                            onChange={e => setPairSeparator(e.target.value)}
                            placeholder='"ny-rad" för att separera vid ny rad'
                        />
                    </div>
                    <div className={styles.buttonWrapper}>
                        <SecondaryButton onClick={() => close()}>Avbryt</SecondaryButton>
                        <PrimaryButton onClick={() => { handleImport(); close(); }}>Importera</PrimaryButton>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default ImportTermsModal;
