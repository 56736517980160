import styles from './BoxButton.module.scss';
import SyncLoader from 'react-spinners/SyncLoader';

function BoxButton({ children, onClick, disabled = false, loading = false }) {
    return (
        <button className={styles.button} onClick={onClick} disabled={disabled}>
            { loading ? null : children }
            <SyncLoader className={styles.loader} loading={loading} size={10} color={'#fff'} />
        </button>
    )
}

export default BoxButton;