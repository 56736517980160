import styles from './Host.module.scss';
import Navbar from '../../components/navbars/Navbar';
import ContainerLg from '../../containers/ContainerLg';
import ScoreTable from '../../components/tests/table/ScoreTable';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import ErrorMessage from '../../components/popups/message/ErrorMessage';
import Popup from 'reactjs-popup';
import { Xmark, OpenNewWindow } from 'iconoir-react';

function Host() {
    const { live_id } = useParams();
    const [ testData, setTestData ] = useState({});
    const [ error, setError ] = useState(null);
    const [ studentData, setStudentData ] = useState({});

    // Retrieve student data
    const retrieveStudentData = useCallback(() => {
        axios.get(`https://api.theodorlundin.se/studian/test/live/get-students?live_id=${live_id}`)
            .then(response => {
                setStudentData(response.data.students);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error with API call: ', error);
            });
    }, [ live_id ]);

    useEffect(() => {
        axios.get(`https://api.theodorlundin.se/studian/test/get/data?live_id=${live_id}`, {
            withCredentials: true
        }).then(response => {
            if (!parseInt(response.data.test.live)) {
                setError('Läxförhöret är inte startat.');
            } else {
                setTestData(response.data.test);
            }
        }).catch(error => {
            console.error('Error with API call: ', error);
        });

        retrieveStudentData();
    }, [ live_id, retrieveStudentData ]);

    useEffect(() => {
        window.setInterval(retrieveStudentData, 500);
        return () => {
            window.clearInterval(retrieveStudentData);
        }
    }, [ retrieveStudentData ]);
 
    // Show error
    if (error) {
        return (
            <div>
                <Navbar />
                <ContainerLg>
                    <div className={styles.container}>
                        <ErrorMessage message={error} />
                    </div>
                </ContainerLg>
            </div>
        );
    }

    // Code modal styles
    const codeModalStyle = {
        borderRadius: '1rem',
        width: 'min(90%, 600px)'
    };

    const codeModalOverlayStyle = {
        backgroundColor: 'rgba(0, 0, 10, 0.5)',
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)'
    };

    return (
        <div>
            <Navbar />
            <ContainerLg>
                <div className={styles.container}>
                    <h1 className={styles.header}>
                        <span className={styles.live}>LIVE</span>
                        Läxförhör: {testData.name}
                    </h1>
                    <div className={styles.tableWrapper}>
                        { testData.questions && <ScoreTable studentData={studentData} questions={JSON.parse(testData.questions)} /> }
                    </div>
                    <div className={styles.footer}>
                        <span className={styles.title}>Låt elever gå med:</span>
                        <div className={styles.actions}>
                            <Popup
                                modal
                                contentStyle={codeModalStyle}
                                overlayStyle={codeModalOverlayStyle}
                                trigger={<span className={styles.code}>{testData.code}<OpenNewWindow className={styles.icon} /></span>}
                            >
                                { close => (
                                    <div className={styles.codeModalContent}>
                                        <Xmark className={styles.close} onClick={close} />
                                        <h1>Gå med i läxförhöret</h1>
                                        <p>Gå till studian.se, klicka på <i>Gå med i läxförhör</i> och skriv in koden:</p>
                                        <span className={styles.code}>{testData.code}</span>
                                    </div>
                                ) }
                            </Popup>
                            <Popup
                                trigger={
                                    <PrimaryButton onClick={() => { navigator.clipboard.writeText(`https://studian.se/test/join/${live_id}`) }}>
                                        Kopiera länk
                                    </PrimaryButton>}
                                position={'bottom center'}
                                on={['click']}
                                closeOnDocumentClick
                            >
                                <span>Länk kopierad!</span>
                            </Popup>
                            
                        </div>
                    </div>
                </div>
            </ContainerLg>
        </div>
    )
}

export default Host;