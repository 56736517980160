import { useState, useEffect } from 'react';
import axios from 'axios';

function AuthNot({ children }) {
    const [isAuthorized, setIsAuthorized] = useState(null);

    useEffect(() => {
        async function checkAuthorization() {
            try {
                const response = await axios.get('https://api.theodorlundin.se/studian/auth/auth', { withCredentials: true });
                setIsAuthorized(response.data.authorized);
            } catch (error) {
                // TODO: Add error box
                console.error('Error checking authorization:', error);
                setIsAuthorized(false);
            }
        }

        checkAuthorization();
    }, []);

    if (isAuthorized === null) {
        return null;
    }

    return isAuthorized ? (
        null
    ) : (
        <>{children}</>
    );
}

export default AuthNot;