import styles from './ErrorMessage.module.scss';
import { XmarkCircle } from 'iconoir-react';

function ErrorMessage({ message }) {
    return (
        <div className={styles.errorMessage}>
            <XmarkCircle className={styles.icon} />
            <span className={styles.message}>{message}</span>
        </div>
    );
}

export default ErrorMessage;