import styles from './CenterContent.module.scss';

function CenterContent({ children }) {
    return (
        <div className={styles.container}>
            {children}
        </div>
    );
}

export default CenterContent;