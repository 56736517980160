import styles from './HomeShortcuts.module.scss';
import Shortcut from './Shortcut';

function HomeShortcuts() {
    return (
        <div className={styles.wrapper}>
            <Shortcut
                shortcutTitle="Utforska läxor"
                shortcutColor="blue"
                onClick={() => window.location.href = '/explore'}
            />
            <Shortcut
                shortcutTitle="Gå med i läxförhör"
                shortcutColor="orange"
                onClick={() => window.location.href = '/test/join'}
            />
            <Shortcut
                shortcutTitle="Skapa läxa"
                shortcutColor="pink"
                onClick={() => window.location.href = '/create/set'}
            />
            <Shortcut
                shortcutTitle="Skapa läxförhör"
                shortcutColor="green"
                onClick={() => window.location.href = '/new/test'}
            />
        </div>
    );
}

export default HomeShortcuts;