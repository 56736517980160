import styles from './WelcomeBox.module.scss';
import IconInput from '../../inputs/IconInput';
import { User, Lock } from 'iconoir-react';
import BoxButton from '../../buttons/BoxButton';
import { useState } from 'react';
import ErrorMessage from '../../popups/message/ErrorMessage';

function LoginBox({ onSubmit, errorMessage, buttonDisabled, loading }) {
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    function handleSubmit() {
        if (!username || !password) {
            return;
        }
        
        onSubmit(username, password);
    }

    return (
        <div className={styles.box}>
            <h1 className={styles.title}>Logga in</h1>
            <div className={styles.inputWrapper}>
                <IconInput 
                    label='Användarnamn' 
                    placeholder='Skriv användarnamn' 
                    type="text" 
                    name="username" 
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                    <User />
                </IconInput>
                <IconInput 
                    label='Lösenord' 
                    placeholder='Skriv lösenord' 
                    type="password" 
                    name="password" 
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                    <Lock />
                </IconInput>
            </div>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <a href="/forgot-password" className={styles.link}>Glömt lösenordet?</a>
            <BoxButton onClick={handleSubmit} disabled={buttonDisabled} loading={loading}>Logga in</BoxButton>
            <a href="/signup" className={styles.footer}>Har du inget konto? <i>Skapa ett!</i></a>
        </div>
    );
}

export default LoginBox;
