import styles from './HomeHeader.module.scss';
import LazyLoad from 'react-lazy-load';

function HomeHeader() {
    return (
        <header className={styles.header}>
            <span className={styles.title}>Det roliga sättet att undervisa</span>
            <LazyLoad>
                <img src="/assets/images/graphics/index-header.png" alt="Studian" />
            </LazyLoad>
        </header>
    );
}

export default HomeHeader;