import Navbar from '../../components/navbars/Navbar';
import GradientContainer from '../../containers/GradientContainer';
import SignUpBox from '../../components/boxes/welcome/SignUpBox';
import { useState } from 'react';
import axios from 'axios';

function Signup() {
    const [ error, setError ] = useState(null);
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    async function handleSubmit(email, username, password, acceptTerms) {
        setButtonDisabled(true);
        setLoading(true);

        setTimeout(() => {
            axios.post('https://api.theodorlundin.se/studian/auth/create-account', {
                email: email,
                username: username,
                password: password,
                accept_terms: acceptTerms
            }, {
                withCredentials: true
            }).then(() => {
                login(username, password);
            }).catch(error => {
                const message = error.response?.data?.error || 'Något gick fel. Försök igen eller kontakta oss.';
                setError(message);
                setButtonDisabled(false);
                setLoading(false);
            });
        }, 1000);

        function login(username, password) {
            axios.post('https://api.theodorlundin.se/studian/auth/login', {
                username: username,
                password: password
            }, {
                withCredentials: true
            }).then(() => {
                window.location.href = '/verify-email';
            }).catch(() => {
                window.location.href = '/login';
            });
        }
    }

    return (
        <div>
            <Navbar />
            <GradientContainer centerContent="true">
                <SignUpBox onSubmit={handleSubmit} errorMessage={error} buttonDisabled={buttonDisabled} loading={loading} />
            </GradientContainer>
        </div>
    );
}

export default Signup;